import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MachineDetails from "./Pages/HeroSections/MachineDetails/MachineDetails";
import ProductionData from "./Pages/HeroSections/ProductionData/ProductionData";
import PlantDetails from "./Pages/HeroSections/PlantDetails/PlantDetails";
import SKUDetails from "./Pages/HeroSections/SKUDetails/SKUDetails";
import PlantSetup from "./Pages/HeroSections/PlantSetup/PlantSetup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Graphs from "./Pages/HeroSections/Graphs/Graphs";
import InkConsumption from "./Pages/HeroSections/InkConsumption/InkConsumption";
import ComponentPo from "./Pages/HeroSections/ComponentPo/ComponentPo";
import UserComp from "./Pages/Manager/UserComp";
// import ManagerDetails from "./Pages/Manager/ManagerDetails";
import PageNotFound from "./Component/PageNotFound/PageNotFound";
import SuperAdmin from "./Pages/SuperUser/SuperAdmin";
import Ribbons from "./Pages/HeroSections/Ribbons/Ribbons";
import ProtectedRoute from "./Pages/ProtectedRoute";
import NamkeenProduction from "./Pages/HeroSections/NamkeenProduction/NamkeenProduction";
// import Namkeen_Navbar from "./Pages/NamkeenUser/NamkeenNavbar";
// import NamkeenNavbar from "./Pages/NamkeenUser/NamkeenNavbar";
// import NamkeenComp from "./Pages/NamkeenUser/NamkeenComp";
// import NamkeenProductionData from "./Pages/HeroSections/NamkeenProductionData/NamkeenProductionData";
import StocklogData from "./Pages/HeroSections/NamkeenSocklogData/StocklogData";
// import NamkeenComponentPo from "./Pages/HeroSections/NamkeenComponentPo/NamkeenComponentPo";
// import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route path="machinedetails" element={<MachineDetails />} />
          <Route path="addproduction" element={<ProductionData />} />
          <Route path="plantdetails" element={<PlantDetails />} />
          <Route path="skudetails" element={<SKUDetails />} />
          <Route path="plantsetup" element={<PlantSetup />} />
          <Route path="graphs" element={<Graphs />} />
          <Route path="ink" element={<InkConsumption />} />
          <Route path="ribbon" element={<Ribbons />} />
        </Route>

        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <UserComp />
            </ProtectedRoute>
          }
        >
          <Route path="managerdetails" element={<ComponentPo />} />
          <Route path="namkeendetails" element={<NamkeenProduction />} />
          {/* <Route
            path="namkeenproductiondata"
            element={<NamkeenProductionData />}
          /> */}
          <Route path="stocklogdata" element={<StocklogData />} />

          {/* <Route path="productiondata" element={<NamkeenComp />} />
          <Route path="stocklogdata" element={<NamkeenComp />} />
          <Route path="productiondata" element={<NamkeenComp />} /> */}
        </Route>

        <Route
          path="/super_user"
          element={
            <ProtectedRoute>
              <SuperAdmin />
            </ProtectedRoute>
          }
        >
          <Route path="graphs" element={<Graphs />} />
          <Route path="ink" element={<InkConsumption />} />
          <Route path="supercomp" element={<ComponentPo />} />
        </Route>

        {/* <Route
          path="/namkeenuser"
          element={
            <ProtectedRoute>
              <NamkeenComp />
            </ProtectedRoute>
          }
        >
          <Route
            path="namkeenproductiondata"
            element={<NamkeenProductionData />}
          />
          <Route path="stocklogdata" element={<StocklogData />} />
          <Route path="componentpo" element={<NamkeenComponentPo />} />
        </Route> */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
    // <NamkeenComp />
  );
}

export default App;
