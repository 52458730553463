import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import toast, { Toaster } from "react-hot-toast";
import Mybutton from "../../../Component/Mybutton/Mybutton";
import axios from "axios";
import { BASE_URL } from "../../../Utils/constants";
import * as XLSX from "xlsx";
import "./componentpo.css";
import CustomLoader from "../../../Component/Loader/CustomLoader";

function ComponentPo() {
  const [compoId, setcompoId] = useState([]);
  const [selcted_compItem, setSelectedCompItem] = useState("");
  const [selcted_compValue, setSelect_compValue] = useState("");
  const [tableData, settableData] = useState([]);
  const [search, setsearch] = useState("");

  const [loader, setLoader] = useState(true);

  const [ponumbers, setPoNumbers] = useState([]); // All PO data
  console.log("ponumbers", ponumbers);

  const [selectedpo, setSelectedPo] = useState(""); // Selected PO number
  const [podata, setPoData] = useState([]); // Data for the selected PO number
  console.log("podata", podata);

  const [activeRowIndex, setActiveRowIndex] = useState(-1); // Data for the selected PO number
  // console.log("podata", podata);
  const [searchpo, setSearchPo] = useState("");

  const [data, setData] = useState([]);
  console.log("data--------", data);

  // console.log("submittedData", submittedData);

  //Pagination Codes
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);

  //urls
  const getComponent_url = BASE_URL + "billmaterial/ComponentAPIView";
  // const postComponent_url = BASE_URL + "billmaterial/componenetePO/";
  const getComponentTable_url = BASE_URL + "billmaterial/componenetePO/";
  const exportall_url = BASE_URL + "billmaterial/ComponentPOListAPIView/";
  const ponumber_url = BASE_URL + "billmaterial/vendorcomponent/"; //all po number data
  // const ponumber_url = BASE_URL + "/billmaterial/vendorcomponeadd/";
  const postData_url = BASE_URL + "billmaterial/vendorcomponeadd/";
  const getDataByPo = BASE_URL + "billmaterial/VendorecomponentAllView/";

  //for post data
  const [formData, setFormData] = useState({
    po_number: "",
    taxinvoiceNo: "",
    taxinvoice_quantity: "",
    received_quantity: "",
    po_quantity: "",
  });

  const [filterDates, setfilterDates] = useState({
    startDate: "",
    endDate: "",
  });

  // for dropdown
  const fetchData = async () => {
    let header = { Authorization: `Token ${localStorage.getItem("token")}` };
    await axios
      .get(getComponent_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          setcompoId(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTableData = async (startDate, endDate) => {
    setLoader(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getComponentTable_url, {
        headers: header,
        params: {
          from_date: startDate || "",
          to_date: endDate || "",
          page: currentPage,
          page_size: pageSize,
        },
      })
      .then((res) => {
        if (res.data.status) {
          // settableData(res.data.data);
          const { data, pagination } = res.data;
          settableData(data);

          // newly added data for pagination

          if (pagination && pagination.length > 0) {
            const totalItems = pagination[0].total;
            const calculatedTotalPages = Math.ceil(totalItems / pageSize);
            setTotalPages(calculatedTotalPages);
          }

          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  // for filter table data as per the po_date
  const filteredAndSortedData = tableData
    .filter((item) => {
      const itemDate = new Date(item.newpo_date);
      const filterStartDate = new Date(filterDates.startDate);
      const filterEndDate = new Date(filterDates.endDate);

      return (
        (!filterDates.startDate || itemDate >= filterStartDate) &&
        (!filterDates.endDate || itemDate <= filterEndDate)
      );
    })
    .sort((a, b) => new Date(b.newpo_date) - new Date(a.newpo_date));

  const handleChange = (id, name, value, index) => {
    var list = ["received_quantity", "taxinvoiceNo", "taxinvoice_quantity"];
    list = list.filter((e) => e !== name);

    setPoData((prevData) =>
      prevData.map((item) =>
        item.id_f === id ? { ...item, [name]: value } : item
      )
    );

    var doit = list.reduce((d, f) => d && podata[index][f] === "", true);

    if (doit && value === "") {
      setActiveRowIndex(-1);
    } else {
      setActiveRowIndex(index);
    }
  };

  const handleSave = async () => {
    const updatedPodata = podata.map((item, index) => ({
      ...item,
      id: item.id || index + 1,
      stock_log: item.stock_log || { balance: "N/A" },
    }));
    console.log("updatedPodata", updatedPodata);
    console.log("updatedPodata", updatedPodata[activeRowIndex]);

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .post(postData_url, updatedPodata[activeRowIndex], {
        headers: header,
        params: { po_number: selectedpo },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setData(res?.data?.data);
          handleSelectPoNumber(selectedpo);
          setActiveRowIndex(-1);
          toast.success(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data.message || err.message);
      });
  };

  const fetchVendorComponentPoData = async () => {
    let header = { Authorization: `Token ${localStorage.getItem("token")}` };
    try {
      const response = await axios.get(ponumber_url, { headers: header });
      const uniquePoNumber = [
        ...new Set(response.data.data.map((item) => item.po_number)),
      ];
      const modifiedData = response.data.data
        .filter((item) => uniquePoNumber.includes(item.po_number))
        .map((item) => ({
          ...item,
          taxinvoiceNo: "",
          received_quantity: "",
          taxinvoice_quantity: "",
        }));
      setPoNumbers(modifiedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchVendorComponentPoData();
  }, []);

  const filterPoNumber = ponumbers.filter((poData) =>
    poData.po_number.toLowerCase().includes(searchpo.toLowerCase())
  );

  const handleSelectPoNumber = (ponumber) => {
    setSelectedPo(ponumber);

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    axios
      .get(getDataByPo, {
        params: { po_number: ponumber },
        headers: header,
      })
      .then((response) => {
        setData(response.data.data);
        const selectedData = ponumbers.filter(
          (item) => item.po_number === ponumber
        );

        if (selectedData.length > 0) {
          const selectedPO = selectedData[0];

          setPoData(selectedData);

          setFormData({
            po_number: selectedPO.po_number || "",
            taxinvoiceNo: selectedPO.taxinvoiceNo || "",
            received_quantity: selectedPO.received_quantity || "",
            taxinvoice_quantity: selectedPO.taxinvoice_quantity || "",
            po_quantity: selectedPO.po_quantity || "",
          });
        } else {
          console.error("No data found for the selected PO number.");
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  };

  const downloadExcel = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.get(exportall_url, {
        headers: header,
        params: {},
      });

      const tableData = response.data.data;

      if (!Array.isArray(tableData)) {
        throw new Error("Expected an array for tableData");
      }

      const dataToExport = tableData.map((item) => ({
        "PO Number": item.newpo_number,
        "Created Date": item.newcreated_date,
        "PO Date": item.newpo_date,
        "Tax Invoice No": item.newtaxinvoiceNo,
        "Received Quantity": item.newreceived_quantity,
        Location: item.newlocation,
        "PO Quantity": item.newpo_quantity,
        Unit: item.newunit,
        Component: item.newcomponent,
        Rate: item.newrate,
        "Basic Rate": item.basic_rate,
        "Total GST (18%)": item.total_gst,
        "Total Amount": item.total_ammount,
      }));

      // Calculate totals
      const totals = tableData.reduce(
        (acc, item) => {
          acc.newreceived_quantity +=
            parseFloat(item.newreceived_quantity) || 0;
          acc.newrate += parseFloat(item.newrate) || 0;
          acc.basic_rate += parseFloat(item.basic_rate) || 0;
          acc.total_gst += parseFloat(item.total_gst) || 0;
          acc.total_amount += parseFloat(item.total_ammount) || 0;
          return acc;
        },
        {
          newreceived_quantity: 0,
          newrate: 0,
          basic_rate: 0,
          total_gst: 0,
          total_amount: 0,
        }
      );

      // Add totals row
      dataToExport.push({
        "PO Number": "Total",
        "Created Date": "",
        "PO Date": "",
        "Tax Invoice No": "",
        "Received Quantity": "",
        Location: "",
        "PO Quantity": "",
        Unit: "",
        Component: "",
        Rate: totals.newrate,
        "Basic Rate": totals.basic_rate,
        "Total GST (18%)": totals.total_gst,
        "Total Amount": totals.total_amount,
      });

      // Convert data to worksheet and save as Excel file
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "ComponentData");

      XLSX.writeFile(workbook, "ComponentData.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Calculate totals
  const totals = tableData.reduce(
    (acc, item) => {
      acc.newreceived_quantity += parseFloat(item.newreceived_quantity) || 0;
      // acc.newtaxinvoice_quantity +=
      //   parseFloat(item.newtaxinvoice_quantity) || 0;
      acc.newrate += parseFloat(item.newrate) || 0;
      acc.basic_rate += parseFloat(item.basic_rate) || 0;
      acc.total_gst += parseFloat(item.total_gst) || 0;
      acc.total_amount += parseFloat(item.total_ammount) || 0;
      return acc;
    },
    {
      newreceived_quantity: 0,
      // newtaxinvoice_quantity: 0,
      newrate: 0,
      basic_rate: 0,
      total_gst: 0,
      total_amount: 0,
    }
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [currentPage, filterDates]);

  const filterdata = filteredAndSortedData.filter(
    (itm) =>
      itm.newpo_number?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newcreated_date?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newpo_date?.toString().includes(search) ||
      itm.newtaxinvoiceNo?.toString().includes(search) ||
      itm.newreceived_quantity?.toString().includes(search) ||
      itm.newlocation?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newpo_quantity?.toString().includes(search) ||
      itm.newunit?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newcomponent?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newrate.toString().includes(search) ||
      itm.basic_rate.toString().includes(search) ||
      itm.total_gst.toString().includes(search) ||
      itm.total_ammount.toString().includes(search)
  );

  return (
    <>
      <div>
        <Toaster />
        {loader ? (
          <CustomLoader />
        ) : (
          <>
            <div
              className="row m-3 main_class"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              <div className="col-12 col-lg-4 col-md-4 px-4 my-2">
                <InputGroup className="input-group-sm">
                  <DropdownButton
                    variant="outline-secondary"
                    title={selectedpo || "Select PO Number"}
                    id="input-group-dropdown-1"
                    onSelect={handleSelectPoNumber}
                  >
                    <Dropdown.Header>
                      <Form.Control
                        placeholder="Search Po Number"
                        value={searchpo}
                        onChange={(e) => setSearchPo(e.target.value)}
                        size="sm"
                      />
                    </Dropdown.Header>
                    {[
                      ...new Set(filterPoNumber.map((item) => item.po_number)), // Get unique PO numbers
                    ].map((poNumber) => {
                      // Find the item associated with the unique PO number
                      const poItem = ponumbers.find(
                        (item) => item.po_number === poNumber
                      );
                      return (
                        // <Dropdown.Item key={poItem.id} eventKey={poItem.po_number}
                        <Dropdown.Item
                          key={poItem.id || poItem.po_number}
                          eventKey={poItem.po_number}
                        >
                          {poItem.po_number}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Control
                    aria-label="Text input with dropdown button"
                    value={selectedpo || "Selected PO Number"}
                    readOnly
                  />
                </InputGroup>
              </div>
            </div>

            <div className="row px-2 mb-2">
              <div className="d-flex justify-content-end align-items-center">
                <div className="col-3">
                  <div className="input-group input-group-sm ps-5 pe-2">
                    <input
                      className="form-control  input-group-sm"
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 table-container mb-2">
              <div className="card card_main">
                <div
                  className="card-body"
                  style={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    {podata.length > 0 ? (
                      <table className="table table-sm">
                        <thead className="table-dark">
                          <tr>
                            <th className=" text-dark">PO Number</th>
                            <th className=" text-dark">PO Date</th>
                            <th className=" text-dark">PO Quantity</th>
                            <th className=" text-dark">Tax Invoice</th>
                            <th className=" text-dark">Received Quantity</th>
                            <th className=" text-dark">Tax Invoice Quantity</th>
                            <th className=" text-dark">Location</th>
                            <th className=" text-dark">Rate</th>
                            <th className=" text-dark">Unit</th>
                            <th className=" text-dark">Component</th>
                            <th className=" text-dark">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {podata.map((item, index) => (
                            <tr key={item.id_f}>
                              <td>{item.po_number}</td>
                              <td>{item.po_date_f}</td>
                              <td>{item.po_quantity_f}</td>
                              <td>
                                <input
                                  type="text"
                                  value={item.taxinvoiceNo}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id_f,
                                      "taxinvoiceNo",
                                      e.target.value,
                                      index
                                    )
                                  }
                                  disabled={
                                    activeRowIndex !== index &&
                                    activeRowIndex !== -1
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.received_quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id_f,
                                      "received_quantity",
                                      e.target.value,
                                      index
                                    )
                                  }
                                  disabled={
                                    activeRowIndex !== index &&
                                    activeRowIndex !== -1
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.taxinvoice_quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id_f,
                                      "taxinvoice_quantity",
                                      e.target.value,
                                      index
                                    )
                                  }
                                  disabled={
                                    activeRowIndex !== index &&
                                    activeRowIndex !== -1
                                  }
                                />
                              </td>
                              <td>{item.location_f}</td>
                              <td>{item.rate_f}</td>
                              <td>{item.unit_f}</td>
                              <td>{item.component}</td>
                              <td>{item.description_f}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center text-danger fw-bold">
                        No data available
                      </p>
                    )}
                  </div>
                  {podata.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-success btn-sm "
                        onClick={handleSave}
                        disabled={activeRowIndex === -1}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="px-3 table-container">
              <div className="card card_main">
                <div
                  className="card-body"
                  style={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    {
                      <table className="table table-bordered table-sm">
                        <thead className="table-dark">
                          <tr>
                            <th>PO Number</th>
                            <th>Component</th>
                            <th>Description</th>
                            <th>Tax Invoice No</th>
                            <th>Tax Invoice Quantity</th>
                            <th>Received Quantity</th>
                            <th>PO Quantity</th>
                            <th>Unit</th>
                            <th>Location</th>
                            <th>Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(data) && data.length > 0 ? (
                            data.map((item, index) => (
                              <tr key={index}>
                                <td>{item.po_number}</td>
                                <td>{item.component}</td>
                                <td>{item.description_f}</td>
                                <td>{item.taxinvoiceNo_f}</td>
                                <td>{item.taxinvoice_quantity_f}</td>
                                <td>{item.received_quantity_f}</td>
                                <td>{item.po_quantity_f}</td>
                                <td>{item.unit_f}</td>
                                <td>{item.location_f}</td>
                                <td>{item.rate_f}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="11"
                                className="text-center text-danger"
                              >
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ComponentPo;
