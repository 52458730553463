import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ManagerTabs.css";

function ManagerTabs() {
  const location = useLocation();
  const navigate = useNavigate();

  const tabsArray = [
    {
      id: 1,
      name: "PO Component",
      path: "/user/managerdetails",
    },
    {
      id: 2,
      name: "BOM",
      path: "/user/namkeendetails",
    },
    // {
    //   id: 3,
    //   name: "Namkeen Production",
    //   path: "/user/namkeenproductiondata",
    // },
    {
      id: 3,
      name: "Stock log",
      path: "/user/stocklogdata",
    },
  ];

  console.log("tabsArray", tabsArray);

  return (
    <div className=" d-flex align-items-center navTabContainer hidescrollbar main_tab fs-6 ">
      {tabsArray &&
        tabsArray.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => {
                navigate(item.path);
              }}
              className={`navTab p-2 m-1 ${
                location.pathname === item.path
                  ? "navTabActive"
                  : "navTabNotActive"
              }`}
            >
              {item.name}
            </div>
          );
        })}
    </div>
  );
}

export default ManagerTabs;
