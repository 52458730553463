import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import CustomLoader from "../../../Component/Loader/CustomLoader";
import { BASE_URL } from "../../../Utils/constants";
import { ImSpinner5 } from "react-icons/im";
import "./PlantDetails.css";

function PlantDetails() {
  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 2000, theme: "colored", type: type });

  const navigate = useNavigate();

  const [plantData, setPlantData] = useState([]);
  const [loader, setLoader] = useState(false);

  //url
  const getPlantDetails_url = BASE_URL + "api/getplant/";

  const getPlantDetails = async () => {
    setLoader(true);

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getPlantDetails_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          setPlantData(res?.data?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          notify("Unathorized user", "error");
          navigate("/");
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    getPlantDetails();
  }, []);

  return (
    <>
      {loader ? (
        <>
          <div className="p-2 table-container">
            <div className="card card_main">
              <div className="card-body ">
                <center>
                  <ImSpinner5 icon="spinner" className="spinner" />
                </center>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="p-lg-3 p-2">
          <div className="card card_main">
            <div
              className="card-body"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              <Table bordered responsive className="table table-sm">
                <thead className="table-dark">
                  <tr>
                    <th className="text-dark ">Plant ID</th>
                    <th className="text-dark"> Name</th>
                    <th className="text-dark">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {plantData?.length ? (
                    plantData.map((item) => {
                      return (
                        <tr key={item.plantID + item.name}>
                          <td className="fw-bold">{item.plantID}</td>
                          <td>{item.name}</td>
                          <td>{item.location}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="text-danger text-center fw-bold"
                      >
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlantDetails;
