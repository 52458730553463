import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../Utils/constants";
import "../Ribbons/Ribbon.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ImSpinner5 } from "react-icons/im";
import Mybutton from "../../../Component/Mybutton/Mybutton";

function Ribbons() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    ribbons: "",
  });

  const [validated, setValidated] = useState(null);

  const [printerData, setPrinterData] = useState([]);
  const [selectedPrinterId, setSelectedPrinterId] = useState("");

  const [ribbonsData, setRibbonsData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("ribbonsData", ribbonsData);

  // URL
  const getskuDetails_url = BASE_URL + "api/GetPostRibbondata/"; //get & Post method
  const getPrinterDetails_url = BASE_URL + "api/printernamelist";
  const getRibbonsData_url = BASE_URL + "api/GetPostRibbondata";

  const getPrinterDetails = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getPrinterDetails_url, { headers: header })
      .then((res) => {
        console.log(res?.data?.data);
        if (res.data.status) {
          setPrinterData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          toast("Unathorized user", "error");
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getPrinterDetails();
  }, []);

  const GetRibbonsData = async () => {
    setLoading(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .get(getRibbonsData_url, { headers: header })
      .then((res) => {
        console.log(res);
        console.log(res.data.data);
        setRibbonsData(res?.data?.data || []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetRibbonsData();
  }, []);

  const handleSubmit = async (e) => {
    const f = e.currentTarget;
    console.log(f);
    e.stopPropagation();
    e.preventDefault();

    if (f.checkValidity() === false) {
      setValidated(true);
      // e.stopPropagation();
      return;
    }
    setValidated(false);

    let data = new FormData();
    data.append("ribbons", form.ribbons);
    data.append("printer_id", selectedPrinterId);

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .post(getskuDetails_url, data, { headers: header })
      .then((res) => {
        toast.success(res.data.messasge);
        setForm({
          ribbons: "",
        });
        setSelectedPrinterId("");
        GetRibbonsData();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        // if (err.response?.status === 401) {
        //   toast("Unathorized user", "error");
        //   navigate("/");
        // }
      });
  };

  return (
    <>
      <div className="row mx-4 mt-3 main-row">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row px-4 my-2">
            <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <InputGroup className="input-group-sm">
                <DropdownButton
                  variant="outline-secondary"
                  title="Select Printer"
                >
                  {printerData.length
                    ? printerData.map((printer) => {
                        return (
                          <Dropdown.Item
                            key={printer?.printername}
                            onClick={() =>
                              setSelectedPrinterId(printer?.printername)
                            }
                          >
                            {printer?.printername}
                          </Dropdown.Item>
                        );
                      })
                    : ""}
                </DropdownButton>
                <Form.Control
                  style={{ border: "1px solid grey" }}
                  readOnly
                  aria-label="Text input with dropdown button"
                  value={
                    selectedPrinterId ? selectedPrinterId : "Select Printer"
                  }
                />
              </InputGroup>
            </div>
            <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <Form.Control
                size="sm"
                style={{ border: "1px solid grey" }}
                required
                value={form.ribbons}
                onChange={(e) =>
                  setForm({
                    ...form,
                    ribbons: e.target.value,
                  })
                }
                name="ribbons"
                type="number"
                placeholder="Add Ribbon rolls"
                className="form-control"
                aria-label="Weight"
                aria-describedby="basic-addon1"
                maxLength={10}
              />
            </div>
          </div>

          <div className="text-center">
            <Mybutton className="" type="submit" name={"Submit"} />
          </div>
        </Form>
      </div>
      <div className="row">
        {loading ? (
          <>
            <div className="p-2 table-container">
              <div className="card card_main">
                <div className="card-body ">
                  <center>
                    <ImSpinner5 icon="spinner" className="spinner" />
                  </center>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="px-4 my-2 table-responsive overflow-auto"
              style={{ maxHeight: "500px" }}
            >
              <table className="table table-bordered table-sm">
                <thead className="table-dark">
                  <tr>
                    <th className="col">Machine Printer</th>
                    <th className="col">Balance</th>
                    <th className="col">Single Print Length</th>
                    <th className="col">Ribbon Length</th>
                  </tr>
                </thead>
                <tbody>
                  {ribbonsData.length > 0 ? (
                    ribbonsData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.machine_printer?.printername || "N/A"}</td>
                        <td>{item.balance || 0}</td>
                        <td>{item.single_print_length || 0}</td>
                        <td>{item.ribbon_length || 0}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Ribbons;
