import React from "react";
import { Outlet } from "react-router-dom";
import CustomNavbar from "./CustomNavbar";
import ManagerTabs from "./ManagerTabs";

function UserComp() {
  return (
    <>
      <div>
        <CustomNavbar />
        <ManagerTabs />
        <Outlet />
      </div>
    </>
  );
}

export default UserComp;
