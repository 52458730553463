import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import "./StocklogData.css";
import axios from "axios";
import { BASE_URL } from "../../../Utils/constants";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function StocklogData() {
  //URL
  const billMaterialStock_Log_Url = BASE_URL + "billmaterial/stocklog_api/";
  const stocklogpost_url = BASE_URL + "billmaterial/stock-data/";

  const [stocklogdata, setstocklogdata] = useState([]);
  const [selectedStockLog, setselectedStockLog] = useState("");
  const [desc, setdesc] = useState("");
  const [data, setData] = useState([]);
  console.log("data", data);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getstocklogdata = () => {
    axios

      .get(billMaterialStock_Log_Url)
      .then((res) => {
        if (res.data.status) {
          console.log("stocklog", res.data.data);
          setstocklogdata(
            res?.data.data.filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.stocklog_item.item_code === item.stocklog_item.item_code
                )
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getstocklogdata();
  }, []);

  const handleSelectedStock = (e) => {
    const selectedValue = e.target.getAttribute("data-value");
    const description = e.target.getAttribute("desc");
    setselectedStockLog(selectedValue);
    setdesc(description);
  };

  useEffect(() => {
    fetchStockData();
  }, [selectedStockLog]);

  const fetchStockData = async () => {
    setLoading(true);
    setError("");
    console.log("selected data", selectedStockLog);

    try {
      const newFormData = new FormData();

      newFormData.append("stocklog_item", selectedStockLog);

      const response = await axios.post(stocklogpost_url, newFormData);
      setData(response.data.Stock_data);
      console.log("Stock_data", response);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = () => {
    if (data.length === 0) {
      alert("No data available to download!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    let d = new Date();
    let formattedDate =
      d.getFullYear().toString() +
      String(d.getMonth() + 1).padStart(2, "0") +
      String(d.getDate()).padStart(2, "0") +
      String(d.getHours()).padStart(2, "0") +
      String(d.getMinutes()).padStart(2, "0") +
      String(d.getSeconds()).padStart(2, "0");

    saveAs(dataBlob, `stocklog-${selectedStockLog}${formattedDate}.xlsx`);
  };

  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div
            className="mb-2"
            style={{
              border: "1px solid gray",
              background: "#d1e7dd",
              borderRadius: "5px",
            }}
          >
            <h4 className="fw-bold mx-2 p-1">STOCK PRODUCTION</h4>
          </div>
          <div
            className="card card_main p-3"
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              backgroundColor: "whitesmoke",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-6 pe-2">
                  <InputGroup className="input-group-sm  mb-3">
                    <DropdownButton
                      variant="outline-secondary"
                      title={selectedStockLog || "Select stocklog"}
                      id="input-group-dropdown-1"
                    >
                      {stocklogdata.length > 0 ? (
                        stocklogdata.map((item) => (
                          <Dropdown.Item
                            key={item.id}
                            data-value={item.stocklog_item.item_code}
                            desc={`${item.stocklog_item.item_code} -- ${item.stocklog_item.item}`}
                            onClick={handleSelectedStock}
                          >
                            {item.stocklog_item.item}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item disabled>
                          No stock log available
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                    <Form.Control
                      aria-label="Text input with dropdown button"
                      value={desc}
                      readOnly
                    />
                  </InputGroup>
                </div>

                <div className="col-6 pe-2 ">
                  <button
                    className="btn btn-primary btn-sm mx-2"
                    onClick={downloadExcel}
                  >
                    Download Excel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card card_main p-3"
          style={{
            border: "1px solid grey",
            backgroundColor: "whitesmoke",
            marginTop: "8px",
          }}
        >
          <div className="row mb-2">
            <table className="table-responsive table-bordered table-sm">
              <thead className="table-dark">
                <tr>
                  <th className="text-dark">Production ID</th>
                  <th className="text-dark">Production </th>
                  <th className="text-dark">Product Code</th>
                  <th className="text-dark">Product Description</th>
                  <th className="text-dark">Tax Invoice No.</th>
                  <th className="text-dark">Tax Invoice Quantity</th>
                  <th className="text-dark">Batch No.</th>
                  <th className="text-dark">StockLog Qty</th>
                  <th className="text-dark">Balance</th>
                  <th className="text-dark"> PO Number</th>
                  <th className="text-dark"> Created Date</th>
                </tr>
              </thead>

              <tbody>
                {data.length > 0 ? (
                  data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id || "N/A"}</td>
                      <td>{item.production || "N/A"}</td>
                      <td>{item.production__Product_code || "N/A"}</td>
                      <td>
                        {item.production__Product_code__description || "N/A"}
                      </td>
                      <td>{item.componentPurches__taxinvoiceNo || "N/A"}</td>
                      <td>
                        {item.componentPurches__taxinvoice_quantity || "N/A"}
                      </td>
                      <td>{item.production__batch_no || "N/A"}</td>
                      <td>{item.stocklog_quantity || "N/A"}</td>
                      <td>{item.balance || "N/A"}</td>
                      <td>{item.po_number || "N/A"}</td>
                      <td>{item.created_date || "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default StocklogData;
