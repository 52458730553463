import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASE_URL } from "../../../Utils/constants";
import "./NamkeenProduction.css";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { ImSpinner5 } from "react-icons/im";

function NamkeenProduction() {
  //url
  const BillMaterialProductApi_url = BASE_URL + "billmaterial/product_api/";
  const BombMaterialProduct_url = BASE_URL + "billmaterial/BomData_apiview/"; //post
  const ProductionNonTeaData_url =
    BASE_URL + "billmaterial/productionnontea_apiview/"; //last table api
  const SubmitProductionData_url = BASE_URL + "billmaterial/Bill_apiview/"; //SUBMIT POST

  //state

  // const [cbbValue, setCBBValue] = useState(0);
  const [loader, setLoader] = useState(false);

  const [BillMaterial, setBillMaterial] = useState([]);
  // console.log("BillMaterial", BillMaterial);

  const [selectedBillMaterial, setSelectedBillMaterial] = useState("");
  const [description, setdescription] = useState("");
  console.log("selectedBillMaterial", selectedBillMaterial);
  const [searchQuery, setSearchQuery] = useState("");

  const [bomdata, setBombData] = useState([]);
  console.log("BombData", bomdata);

  const [productionData, setProductionData] = useState([]);
  const [filteredProductionData, setFilteredProductionData] = useState([]);

  // states
  // const [quantity, setQuantity] = useState("");
  // const [unit, setUnit] = useState("");

  const [formData, setFormData] = useState({
    quantity: "",
    unit: "",
  });
  const [batchNumber, setBatchNumber] = useState("");

  const [manufacturingDate, setManufacturingDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  //calculation State
  const [calculatedQuantities, setCalculatedQuantities] = useState({});
  console.log("calculatedQuantities", calculatedQuantities);

  const [consumptionQuantities, setConsumptionQuantities] = useState({});
  console.log("consumptionQuantities", consumptionQuantities);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Filter products based on search input
  const filteredBillMaterial = BillMaterial.filter(
    (item) =>
      item.product_code.toLowerCase().includes(searchQuery) ||
      item.description.toLowerCase().includes(searchQuery)
  );

  const handleManufacturingDateChange = (e) => {
    const selectedDate = e.target.value;
    setManufacturingDate(selectedDate);

    // Calculate expiry date (6 months ahead)
    if (selectedDate) {
      const date = new Date(selectedDate);
      date.setMonth(date.getMonth() + 6); // Add 6 months

      // Format as YYYY-MM-DD (required for input[type="date"])
      const formattedExpiryDate = date.toISOString().split("T")[0];
      setExpiryDate(formattedExpiryDate);
    } else {
      setExpiryDate("");
    }
  };

  const getBillMaterialProductApi = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .get(BillMaterialProductApi_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          console.log(res?.data?.data);
          setBillMaterial(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBombDataApi = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .post(
        `${BombMaterialProduct_url}?product=${selectedBillMaterial}`,
        {
          product: selectedBillMaterial,
        },
        { headers: header }
      )
      .then((res) => {
        if (res?.data?.status) {
          // console.log(res.data.data);
          setBombData(
            res.data.data.sort((a, b) => {
              if (a.priority_No < b.priority_No) {
                return -1;
              }
              if (a.priority_No > b.priority_No) {
                return 1;
              }
              return 0;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedBillMaterial) {
      getBombDataApi();
      // Filter production data based on selected Bill Material
      console.log("productionData", productionData);

      const filteredData = productionData.filter(
        (item) => item.Product_code?.product_code === selectedBillMaterial
      );
      // const filteredData = productionData.filter(
      //   (item) => item?.Product_code === selectedBillMaterial
      // );
      console.log("filteredData", filteredData);

      const sortedData = filteredData.sort(
        (a, b) => new Date(b.update_date) - new Date(a.update_date)
      );
      console.log("sortedData ", sortedData);
      setFilteredProductionData(sortedData);
    }
  }, [selectedBillMaterial, productionData]);

  useEffect(() => {
    getBillMaterialProductApi();
  }, []);

  const handleQuantityAndUnitChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [name]: value };
      // Initialize new quantities
      const newCalculatedQuantities = {};
      const newConsumptionQuantities = {};
      console.log("updatedFormData.unit", updatedFormData.unit);

      // Calculate values based on unit type
      if (updatedFormData.unit === "kg") {
        bomdata.forEach((item) => {
          if (item.component__component_type !== "tape") {
            const calculatedValue =
              parseFloat(updatedFormData.quantity) * parseFloat(item.quantity);
            newCalculatedQuantities[item.component__item] = calculatedValue;
            newConsumptionQuantities[item.component__item] = calculatedValue;
          }
        });

        let bom_tape = bomdata.find(
          (e) => e.component__component_type === "tape"
        );

        let bom_cbb = bomdata.find((e) => {
          console.log("Checking:", e.component__component_type); // Debug each component type
          return e.component__component_type === "cbb";
        });

        console.log("bom_cbb:", bom_cbb); //

        if (bom_tape && bom_cbb) {
          console.log("newCalculatedQuantities", newCalculatedQuantities);
          const cbbCalculatedValue =
            newCalculatedQuantities[bom_cbb.component__item] || 0;
          console.log("cbbCalculatedValue", cbbCalculatedValue);

          newCalculatedQuantities[bom_tape.component__item] =
            (newCalculatedQuantities[bom_cbb.component__item] *
              bom_tape.quantity) /
            72;
          // console.log(newCalculatedQuantities[bom_tape.component__item]);

          newConsumptionQuantities[bom_tape.component__item] =
            (newConsumptionQuantities[bom_cbb.component__item] *
              bom_tape.quantity) /
            72;
          // console.log(newConsumptionQuantities[bom_tape.component__item]);
          // console.log("bomcbb", newCalculatedQuantities.bom_cbb);
          // setCBBValue(cbbCalculatedValue);
        }
      } else if (updatedFormData.unit === "box") {
        bomdata.forEach((item) => {
          if (item.component__component_type !== "tape") {
            const boxKgQuantity =
              BillMaterial.find(
                (material) => material.product_code === selectedBillMaterial
              )?.box_kgquantity || 1;

            const calculatedValue =
              parseFloat(updatedFormData.quantity) *
              parseFloat(item.quantity) *
              boxKgQuantity;

            newCalculatedQuantities[item.component__item] = calculatedValue;
            newConsumptionQuantities[item.component__item] = calculatedValue;
          }
        });

        let bom_tape = bomdata.find(
          (e) => e.component__component_type === "tape"
        );
        let bom_cbb = bomdata.find(
          (e) => e.component__component_type === "cbb"
        );

        if (bom_tape && bom_cbb) {
          newCalculatedQuantities[bom_tape.component__item] =
            (newCalculatedQuantities[bom_cbb.component__item] *
              bom_tape.quantity) /
            72;
          newConsumptionQuantities[bom_tape.component__item] =
            (newConsumptionQuantities[bom_cbb.component__item] *
              bom_tape.quantity) /
            72;
        }
      }

      // Update the state with new calculated values
      setCalculatedQuantities(newCalculatedQuantities);
      setConsumptionQuantities(newConsumptionQuantities);

      return updatedFormData;
    });
  };

  const handleSelectedBillMaterial = (e) => {
    // const selectedValue = e.target.value;

    //new line added
    const selectedValue = e.target.getAttribute("data-value");
    const jjh = e.target.getAttribute("desc");
    console.log("selectedValue", selectedValue);

    setSelectedBillMaterial(selectedValue);
    setdescription(jjh);
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        quantity: "",
        unit: "",
      };
    });
    setBatchNumber("");
    setManufacturingDate("");
    setExpiryDate("");
    setCalculatedQuantities({});
    setConsumptionQuantities({});
  };

  // Update Consumption Quantity when user edits input

  const handleConsumptionChange = (item, type, value) => {
    const numericValue = parseFloat(value) || 0;
    // console.log("numericValue", numericValue);
    let tape_com = bomdata.find((d) => d.component__component_type === "tape");

    if (type === "cbb") {
      setConsumptionQuantities((prev) => ({
        ...prev,
        [item]: numericValue,
        [tape_com.component__item]: (numericValue * tape_com.quantity) / 72,
      }));
    } else {
      setConsumptionQuantities((prev) => ({
        ...prev,
        [item]: numericValue,
      }));
    }
  };

  const ProductionNonTeaData = async (product_code) => {
    setLoader(true);

    let header = { Authorization: `Token ${localStorage.getItem("token")}` };

    await axios
      .get(ProductionNonTeaData_url + `?product_code=${product_code}`, {
        headers: header,
      })
      .then((res) => {
        if (res?.data?.status) {
          console.log("production non tea data", res.data);
          setProductionData(res?.data?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    ProductionNonTeaData(selectedBillMaterial);
  }, [selectedBillMaterial]);

  const handleSubmit = async () => {
    if (!batchNumber) {
      toast.error("Batch Number is required");
      return;
    } else if (!manufacturingDate) {
      toast.error("Manufacturing Date is required");
      return;
    } else if (!expiryDate) {
      toast.error("Expiry Date is required");
      return;
    } else if (!formData.quantity) {
      toast.error("Quantity is required");
      return;
    } else if (!formData.unit) {
      toast.error("Unit is required");
      return;
    } else if (!selectedBillMaterial) {
      toast.error("Bill Material is required");
      return;
    }

    if (!expiryDate && manufacturingDate) {
      const date = new Date(manufacturingDate);
      date.setMonth(date.getMonth() + 6);
      setExpiryDate(date.toISOString().split("T")[0]);
    }

    // Create a new FormData object
    const newFormData = new FormData();

    // Append the form data fields
    newFormData.append("product_code", selectedBillMaterial);
    newFormData.append("batch_no", batchNumber);
    newFormData.append("manufacturing", manufacturingDate);
    newFormData.append("expiry_date", expiryDate);
    newFormData.append("pro_quantity", formData.quantity);
    newFormData.append("unit", formData.unit);

    // Create an array for the components with the desired structure
    const data = Object.entries(consumptionQuantities).map(([item, qty]) => {
      // Find matching bomb item
      const bombItem = bomdata.find((bomb) => bomb.component__item === item);
      console.log("bombItem", bombItem);
      // Return the correct component code if found
      return {
        component: bombItem ? bombItem.component__item_code : item, // Use component__item_code if found, otherwise fallback to item
        consumption_qty: qty, // Ensure consistent naming (consumption_qty)
        // consumption_qty: parseFloat(qty.toFixed(2)),
      };
    });

    newFormData.append("data", JSON.stringify(data));

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .post(SubmitProductionData_url, newFormData, { headers: header })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          toast.success("Production data submitted successfully!");
          setBatchNumber("");
          setManufacturingDate("");
          setExpiryDate("");
          setCalculatedQuantities({});
          setConsumptionQuantities({});
          // setdescription("");
          setFormData({
            quantity: "",
            unit: "",
          });
          ProductionNonTeaData(selectedBillMaterial);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || "Network Error");
      });
  };

  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div
            className="mb-2"
            style={{
              border: "1px solid gray",
              background: "#d1e7dd",
              borderRadius: "5px",
            }}
          >
            <h4 className="fw-bold mx-2 p-1">BOM</h4>
          </div>
        </div>
        <div
          className="card card_main p-3"
          style={{
            border: "1px solid grey",
            borderRadius: "5px",
            backgroundColor: "whitesmoke",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-6 pe-2">
                <InputGroup className="input-group-sm mb-2">
                  <DropdownButton
                    variant="outline-secondary"
                    title={selectedBillMaterial || "--- Select Product ---"}
                    id="input-group-dropdown-1"
                  >
                    {/* Search Box */}
                    <Dropdown.Header>
                      <Form.Control
                        placeholder="Search Product"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        size="sm"
                      />
                    </Dropdown.Header>

                    {filteredBillMaterial.length > 0 ? (
                      filteredBillMaterial.map((item) => (
                        <Dropdown.Item
                          key={item.product_code}
                          data-value={item.product_code}
                          desc={item.product_code + "--" + item.description}
                          onClick={handleSelectedBillMaterial}
                        >
                          [{item.product_code}] -- {item.description} --{" "}
                          {item.box_kgquantity}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No products found</Dropdown.Item>
                    )}
                  </DropdownButton>

                  <Form.Control
                    aria-label="Selected product"
                    value={description || ""}
                    readOnly
                  />
                </InputGroup>

                <div
                  className="input-group input-group-sm mb-2"
                  style={{ width: "100%" }}
                >
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ fontSize: "14px" }}
                  >
                    Quantity
                  </span>
                  <input
                    name="quantity"
                    type="number"
                    className="form-control"
                    placeholder="Enter Quantity"
                    value={formData.quantity}
                    onChange={handleQuantityAndUnitChange}
                    style={{ fontSize: "14px" }}
                    disabled={!selectedBillMaterial}
                  />
                </div>
                <select
                  className="form-select form-select-sm mb-2"
                  name="unit"
                  value={formData.unit}
                  onChange={handleQuantityAndUnitChange}
                  style={{ width: "100%", fontSize: "14px" }}
                  disabled={!selectedBillMaterial}
                >
                  <option value="">---Select Unit---</option>
                  <option value="kg">kg</option>
                  <option value="box">Box</option>
                </select>
              </div>
              <div className="col-6">
                <div
                  className="input-group input-group-sm mb-2"
                  style={{ width: "100%" }}
                >
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ fontSize: "14px" }}
                  >
                    Batch Number
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Batch Number"
                    value={batchNumber}
                    onChange={(e) => setBatchNumber(e.target.value)}
                    style={{ fontSize: "14px" }}
                    disabled={!selectedBillMaterial}
                  />
                </div>
                <div
                  className="input-group input-group-sm mb-2"
                  style={{ width: "100%" }}
                >
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ fontSize: "14px" }}
                  >
                    Manufacturing Date
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    value={manufacturingDate}
                    // onChange={(e) => setManufacturingDate(e.target.value)}
                    onChange={handleManufacturingDateChange}
                    style={{ fontSize: "14px" }}
                    disabled={!selectedBillMaterial}
                  />
                </div>
                <div
                  className="input-group input-group-sm mb-2"
                  style={{ width: "100%" }}
                >
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ fontSize: "14px" }}
                  >
                    Expiry Date
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    value={expiryDate}
                    // onChange={(e) => setExpiryDate(e.target.value)}
                    style={{ fontSize: "14px" }}
                    disabled={!selectedBillMaterial}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {selectedBillMaterial && (
          <>
            <div
              className="card card_main p-3"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
                marginTop: "8px",
              }}
            >
              <div className="row mb-2 table-responsive-sm">
                <table
                  className="table table-hover table-bordered table-sm"
                  style={{ border: "1px solid #2397c152" }}
                >
                  <thead className="table-dark">
                    <tr>
                      <th>Component</th>
                      <th scope="col">Quantity (Unit)</th>
                      <th scope="col">Ideal Qty</th>
                      <th scope="col">Consumption Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bomdata.length > 0 ? (
                      bomdata.map((item, index) => (
                        <tr key={index}>
                          <td>{item.component__item}</td>
                          <td>
                            {item.quantity} ({item.valueunit})
                          </td>
                          <td>
                            <input
                              type="number"
                              value={
                                calculatedQuantities[
                                  item.component__item
                                ]?.toFixed(5) || ""
                              }
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={
                                parseFloat(
                                  consumptionQuantities[item.component__item]
                                ) || ""
                              }
                              onChange={(e) =>
                                handleConsumptionChange(
                                  item.component__item,
                                  item.component__component_type,
                                  e.target.value
                                )
                              }
                            />
                            {item.component__component_type === "tape"
                              ? "rolls/nos"
                              : item.valueunit}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="4"
                          className="text-center text-danger fw-bold"
                        >
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-success btn-sm"
                    style={{
                      border: "none",
                      backgroundColor: "darkcyan",
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row mt-2">
          <div
            className="mb-2"
            style={{
              border: "1px solid gray",
              background: "#d1e7dd",
              borderRadius: "5px",
              marginTop: "8px",
            }}
          >
            <h4 className="fw-bold mx-2 p-1" style={{}}>
              Production FG Data
            </h4>
          </div>
          <div
            className="card card_main p-3"
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              backgroundColor: "whitesmoke",
              marginBottom: "8px",
              marginTop: "8px",
            }}
          >
            <div className="table-responsive">
              {/* <table
                className="table table-hover table-bordered  table-sm"
                style={{ border: "1px solid #2397c152" }}
              >
                <thead className="table-dark">
                  <tr>
                    <th scope="col">Production Code</th>
                    <th scope="col">Product Code/Description </th>
                    <th scope="col">Pro Quantity In Kg</th>
                    <th scope="col">No Of Case</th>
                    <th scope="col">No Of Case Component</th>
                    <th scope="col">Batch No</th>
                    <th scope="col">Manufacturing Date</th>
                    <th scope="col">Expiry Date</th>
              
                  </tr>
                </thead>
                <tbody>
                  {filteredProductionData.length === 0 ? (
                    <tr>
                      <td
                        colSpan="9"
                        className="text-center text-danger fw-bold"
                      >
                        No Data Found
                      </td>
                    </tr>
                  ) : (
                    filteredProductionData.map((item, i) => (
                      <tr key={i}>
                        <td>PRD{item.id}</td>
                        <td>
                          [{item.Product_code?.product_code}] -{" "}
                          {item.Product_code?.description}
                        </td>
                        <td>{item.pro_quantity}</td>
                        <td>
                          {item.component_consumption
                            ?.find((comp) => comp.component_type === "cbb")
                            ?.consumption_qty.toFixed(2) || "N/A"}
                        </td>
                        <td>
                          {item.component_consumption?.length > 0 ? (
                            <ul className="list-unstyled">
                              {item.component_consumption.map((comp, index) => (
                                <li key={index}>{comp.item}</li>
                              ))}
                            </ul>
                          ) : (
                            "0"
                          )}
                        </td>
                        <td>{item.batch_no}</td>
                        <td>{item.manufacturing}</td>
                        <td>{item.expiry_date}</td>
                       
                      </tr>
                    ))
                  )}
                </tbody>
              </table> */}
              {loader ? (
                <div className="p-2 table-container">
                  <div className="card card_main">
                    <div className="card-body">
                      <center>
                        <ImSpinner5 icon="spinner" className="spinner" />
                      </center>
                    </div>
                  </div>
                </div>
              ) : (
                <table
                  className="table table-hover table-bordered table-sm"
                  style={{ border: "1px solid #2397c152" }}
                >
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">Production Code</th>
                      <th scope="col">Product Code/Description </th>
                      <th scope="col">Pro Quantity In Kg</th>
                      <th scope="col">No Of Case</th>
                      <th scope="col">No Of Case Component</th>
                      <th scope="col">Batch No</th>
                      <th scope="col">Manufacturing Date</th>
                      <th scope="col">Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProductionData.length === 0 ? (
                      <tr>
                        <td
                          colSpan="9"
                          className="text-center text-danger fw-bold"
                        >
                          No Data Found
                        </td>
                      </tr>
                    ) : (
                      filteredProductionData.map((item, i) => (
                        <tr key={i}>
                          <td>PRD{item.id}</td>
                          <td>
                            [{item.Product_code?.product_code}] -{" "}
                            {item.Product_code?.description}
                          </td>
                          <td>{item.pro_quantity}</td>
                          <td>
                            {item.component_consumption
                              ?.find((comp) => comp.component_type === "cbb")
                              ?.consumption_qty.toFixed(2) || "N/A"}
                          </td>
                          <td>
                            {item.component_consumption?.length > 0 ? (
                              <ul className="">
                                {item.component_consumption.map(
                                  (comp, index) => (
                                    <li key={index}>{comp.item}</li>
                                  )
                                )}
                              </ul>
                            ) : (
                              "0"
                            )}
                          </td>
                          <td>{item.batch_no}</td>
                          <td>{item.manufacturing}</td>
                          <td>{item.expiry_date}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NamkeenProduction;
